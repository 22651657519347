import React, { useState } from "react";
import { Box, Button, Text, Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom"; // Import Link component

const HeaderSecond = () => {

  const companyName = process.env.REACT_APP_COMPANY_NAME;
  
  const img = "./lineImage.png";
  const message = `Welcome to ${companyName} - Advocate, Navigate, Elevate.`;
  const secondMessage = "Professional, Fast & Quality"; 
  const thirdMessage =
    "Partner with us to elevate your business above the competition with enhanced quality and reduced costs. Let us manage your business processes, freeing you to drive growth and innovation.";
  return (
    <Box style={{ position: "relative" }} textAlign={"center"}>
      {/* Apply styles to the span elements */}
      <Text
        style={{
          marginTop: "70px",
          fontSize: "35px",
          fontWeight: "bold",
          top: "30%",
        }}
      >
        {message}
      </Text>
      <Text
        style={{
          fontSize: "18px",
          color: "#26252c",
          lineHeight: "35px",
          textAlign: "center",
          fontWeight: "400",
          fontStyle: "normal",
          marginTop: "16px",
          fontWeight: "normal",
          top: "30%",
        }}
      >
        {secondMessage}
      </Text>
        <br/>
      
      <Link to="/appointment">
        <Button
        w= {'50%'}
          colorScheme="teal"
        >
          Start a Free Trial
        </Button>
        <br/>
        <br/>

        {/* */}
    
      </Link>
    
      {/* </Box> */}
      <Text
        style={{
        //   textAlign: "justify", // Center the text horizontally
          fontSize: "15px",
          fontWeight: "normal", // Set font weight to normal
          fontStyle: "italic", // Set font style to italic
          marginTop: "10px", // Add margin from the top
          marginLeft: "auto", // Center the text horizontally
          marginRight: "auto", // Center the text horizontally
          maxWidth: "800px", // Set maximum width for the text
        }}
      >
        {thirdMessage}
      </Text>
    </Box>
  ); 
};

export default HeaderSecond;
