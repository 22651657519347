import React ,{useEffect}from 'react';
import NavbarTopFirst from '../Navbar/NavbarTopFirst';
import Footer from '../Footer/Footer';
import { FaFileAlt } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

const TermsAndConditions = () => {

  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 704px)' });
     // Scroll to the top of the page when the component mounts
     useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
      <NavbarTopFirst />
      <header style={{ position: 'relative', width: '100%', backgroundColor: '#008080', color: 'white', padding: '20px', marginBottom: '1%', marginTop: '130px' }}>
        <h1 style={{ margin: 0, fontWeight: 'bold', fontSize: '2.5rem', textAlign: 'center' }}>Terms and Conditions</h1>
      </header>

      <div style={{
        position: 'fixed',
        margin: 10,
        top: '130px',
        width: '100%',
        zIndex: 1000,
        backgroundColor: '#fff',
        // borderBottom: '1px solid #e0e0e0',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }} />

      <main style={{
        padding: isTabletOrMobile ? '40px' : '20px',
         backgroundColor: '#f9f9f9',
         margin: isTabletOrMobile ? '-20px' : '20px',
        
    
        maxWidth: '1500px',
        borderRadius: '10px',
        marginBottom: '20px',
         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: '"Arial", sans-serif',
      }}>
        <section style={{ marginBottom: '40px' }}>
          <h2 style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '2rem',
            color: '#008080',
            borderBottom: '3px solid #008080',
            paddingBottom: '10px',
            marginBottom: '20px'
          }}>
            <FaFileAlt size={40} style={{ marginRight: '15px', color: '#008080' }} />
            General Terms and Conditions
          </h2>

          <p style={{ fontSize: '1rem', lineHeight: '1.8', color: '#333333', textAlign: 'justify' }}>
            General Terms and Conditions ("T&C") govern your access to and use of the website of  {companyName}  ("Provider", "we", "us", or "our"), an LPO (Legal Process Outsourcing) service provider based in Bengaluru, India. By using the website, you acknowledge and agree to be bound by these T&C.
          </p>
        </section>

        {[
          { id: 1, title: 'General', content: `The website is owned and operated by <strong>${companyName} </strong> and its associated parties. <strong>These T&C apply to:\n</strong><ul><li>Visitors: Anyone accessing or viewing our website.</li><li>Registered Users: Anyone providing personal information (name, email, etc.) through the website’s contact forms or via email.</li></ul>Collectively, both categories are referred to as Users ("you" or "your"). By continuing to access or use the website, you agree to these T&C.` },
          { id: 2, title: 'Eligibility', content: 'By accessing or using the website, you represent and warrant that:<ul><li>You are at least 18 years of age.</li><li>You have the legal capacity to enter into a binding contract.</li><li>Your use of the website complies with all applicable laws and regulations.</li></ul>' },
          { id: 3, title: 'Description of Services', content: `Our website provides information regarding the legal and paralegal services offered by ${companyName} to U.S.-based attorneys and law firms. This includes details about our services, client success stories, the benefits of outsourcing, FAQs, and contact details. Any new features or updates to our website, unless otherwise stated, will also be governed by these T&C.` },
          { id: 4, title: 'Website Access and Use', content: `When accessing and using our website, you agree to the following:<ul><li>The website is provided "as-is" and "as-available". We are not responsible for any interruptions, errors, or issues in website availability.</li><li>You are responsible for obtaining internet access and any associated fees.</li><li>You must not disrupt the website’s functionality, overload its infrastructure, or attempt to hack or bypass its security features.</li><li>You may not transmit any harmful or illegal content via the website.</li></ul>`},
          { id: 5, title: 'Website Content', content: `By viewing and using the content provided on our website, you acknowledge and agree:<ul><li>The content on the website is for informational purposes only and does not constitute legal advice.</li><li>We are not liable for any errors or omissions in the website content or for any loss resulting from its use.</li><li>Content may be updated or changed without prior notice and may not take into account specific laws in your jurisdiction.</li></ul>`},
          { id: 6, title: 'Data and User Content Input', content: `For purposes of this section, User Content includes personal information and other data provided by you through the website. By submitting any information, you agree that:<ul><li>The information you provide is accurate and up to date.</li><li>You will not upload or transmit any unlawful, offensive, or malicious content.</li><li>We reserve the right to review and remove any User Content at our discretion, especially if required by law or to protect other users.</li></ul>`},
          { id: 7, title: 'Proprietary Rights', content: `All intellectual property rights in the website and its contents, including text, graphics, logos, and software, belong to ${companyName} or its licensors. You are granted a limited license to access and use the website for personal purposes only. Without prior written permission, you may not:<ul><li>Copy, modify, or distribute any content from the website.</li><li>Use the website content for commercial purposes.</li></ul>`},
          { id: 8, title: 'Exclusion of Liability', content: `${companyName} and its affiliates are not liable for any direct or indirect damages arising from your use or inability to use the website, including damages from data loss, profits, or goodwill. This limitation applies even if we have been advised of such potential damages.`},
          { id: 9, title: 'Indemnity', content: `You agree to indemnify and hold harmless ${companyName},Its employees, and associates from any claims, damages, liabilities, and costs (including legal fees) arising from your violation of these T&C or misuse of the website and its content.`},
          { id: 10, title: 'Modifications to the Website and T&C', content: `We reserve the right to modify or discontinue any part of the website or these T&C at any time. Any changes will be posted on the website and will take immediate effect. It is your responsibility to regularly review these T&C for updates. Continued use of the website after any changes indicates acceptance of the new T&C.`},
          { id: 11, title: 'Off-Site Links', content: `Our website may provide links to third-party websites for your convenience. We have no control over these websites and are not responsible for their content, policies, or practices. Access to these sites is at your own risk.`},
          { id: 12, title: 'Email Policy', content: `We may communicate with you via email if you provide your contact information. We will not share personal contact details of our staff or affiliates. We reserve the right to delete any inappropriate or unsolicited emails and may store email correspondence for a defined period.`},
          { id: 13, title: 'Privacy Policy', content: 'Our <a href="/privacy-policy" style={{ color: "#004d40", textDecoration: "underline" }}>Privacy Policy</a> outlines how we handle your personal data, including collection, use, and storage. It forms part of these T&C, and by using our website, you agree to our data handling practices.'},
          { id: 14, title: 'No Third-Party Beneficiaries', content: `These T&C apply solely between ${companyName} and its Users. No other individual or entity will have any rights or benefits under these T&C.`},
          { id: 15, title: 'Notices', content: `We may provide notices or updates about the website or services via email. By using the website, you agree to receive such communications.`},
          { id: 16, title: 'Governing Law', content: `These T&C are governed by the laws of India, and any disputes arising from these T&C will be subject to the jurisdiction of the courts in Bengaluru, India.`},
          { id: 17, title: 'Jurisdiction', content: `You agree that any disputes relating to these T&C or your use of the website will be exclusively heard by the courts of Bengaluru, India.`},
          { id: 18, title: 'Entire Agreement', content: `These T&C constitute the entire agreement between you and ${companyName} regarding your use of the website, superseding any prior agreements or understandings.`},
          { id: 19, title: 'Waiver and Severability', content: `Failure by ${companyName} to enforce any right or provision of these T&C shall not constitute a waiver. If any part of these T&C is deemed unenforceable by a court, the remaining provisions shall remain in effect.`},
          { id: 20, title: 'Violations and Reporting', content: `If you become aware of any violations of these T&C, please report them to ${companyName} via email at <a href="mailto:support@upholdlegal.com" style={{ color: "#004d40", textDecoration: "underline" }}><strong>support@upholdlegal.com</strong></a>.`},
        ].map(section => (
          <section key={section.id} style={{ marginBottom: '40px', padding: '20px', backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05)' }}>
            <h3 style={{ color: '#008080', margin: '20px 0', fontSize: '1.5rem', fontWeight: '600' }}>{section.id}. {section.title}</h3>
            <p style={{ fontSize: '1rem', lineHeight: '1.8', color: '#333333' }} dangerouslySetInnerHTML={{ __html: section.content }} />
          </section>
        ))}
      </main>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
