import NavbarTopFirst from '../Navbar/NavbarTopFirst';

import React, { useState } from 'react';

import Footer from '../Footer/Footer';
import Appointment from './Appointment';
import { useMediaQuery } from "react-responsive"; // Importing useMediaQuery hook from react-responsive
import { FaHome } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";

import { ChakraProvider } from '@chakra-ui/react';

import { PhoneIcon, EmailIcon, InfoOutlineIcon, WarningIcon, AddIcon } from '@chakra-ui/icons';

// Define shadow styles
const shadowStyle = {
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adding shadow effect
  padding: '20px',
  borderRadius: '10px',
  backgroundColor: 'white',
};

// Additional styles for sections
const sectionShadowStyle = {
  ...shadowStyle,
  marginBottom: '20px',
  height: '100%', // Ensures both sections take equal height
};

// Wrapper style to maintain equal height of both boxes
const flexContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '100%', // To ensure both boxes take up equal height
  alignItems: 'stretch',
};

const Contact = () => {

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 704px)" });
  const companyName = process.env.REACT_APP_COMPANY_NAME;

  return (
    <div>
      <NavbarTopFirst />

      <div>
        <div style={{ position: 'relative', marginTop: '135px' }}>
          <img src="/map.jpg" alt="Google Map" style={{ width: '100%', height: '100px', objectFit: 'cover' }} />
          <div style={{ position: 'absolute', top: '50%', left: '20px', transform: 'translateY(-50%)', color: '#fff', zIndex: '1' }}>
            <h1 style={{ padding: '20px', borderRadius: '15px', fontWeight: 'bold', fontSize: '2.5rem' }}>Contact Us</h1>
          </div>
        </div>

        <div>
          <h5 style={{ fontWeight: 'bold', color: 'white', backgroundColor: "#008080", marginTop: '20px', marginBottom: '10px', padding: '20px',fontSize: '1.2em'  }}>We’re At Your Service</h5>
          <hr style={{ border: '5px solid #ccc', marginBottom: '20px' }} />

          <div style={{ borderLeft: isTabletOrMobile ? '5px solid transparent' : '70px solid transparent', borderRight: isTabletOrMobile ? '5px solid transparent' : '80px solid transparent', width: isTabletOrMobile ? '100%' : '96%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'justify', padding: '20px', ...sectionShadowStyle }}>
            <p>If you are looking to contact us for further information on our services, any general questions, or to discuss your needs and options, please contact us and we will be happy to provide you with all the information you need.</p>
            <p style={{ alignItems: 'center', height: '10px', padding: '30px', marginBottom: '0px' }}>Thank you for your interest in <span style={{ fontWeight: 'bold' }}>{companyName}</span>.</p>
          </div>
        </div>
      </div>

      {isTabletOrMobile ? (
        <ChakraProvider>
          <div style={{ padding: '10px', alignItems: 'flex-start', }}>
            <div style={{ width: '100%', padding: '20px', ...sectionShadowStyle }}> {/* Apply shadow here */}
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <h5 style={{ color: '#008080', fontWeight: 'bold', marginTop: '20px', marginBottom: '10px',marginLeft:'24px'}}>We Will Here For You!</h5>

                <p style={{ alignItems: 'auto',marginLeft:'25px' }}>
                  <IoIosPhonePortrait style={{ fontSize: '5em', color: 'black',marginLeft:'9px' }} /> {/* Adjust size and color */}
                  <span>9430085302</span>
                </p>
                <p style={{ alignItems: 'auto',marginLeft:'25px' }}>
                  <MdMarkEmailRead style={{ fontSize: '4em', color: 'black',marginLeft:'9px' }} /> {/* Adjust size and color */}
                  <span>support@upholdlegal.com</span>
                </p>
                <p style={{ alignItems: 'auto',marginLeft:'25px' }}>
                  <FaHome style={{ fontSize: '4em', color: 'black' ,marginLeft:'9px'}} /> {/* Adjust size and color */}
                  <span style={{ display: 'block' }}>560063, Bengaluru</span>
                  <span style={{ display: 'block' }}>Karnataka, India</span>
                </p>
              </div>
            </div>

            <div style={{ flex: 1, padding: '15px', ...sectionShadowStyle }}> {/* Apply shadow here */}
              <Appointment />
            </div>
          </div>
        </ChakraProvider>

      ) : (
        <ChakraProvider>
          <div style={{ display: 'flex', padding: '10px' }}>
            <div style={{ width: '40%', padding: '20px' }}>
              <div style={{ ...sectionShadowStyle }}> {/* Apply shadow here */}
                <h5 style={{ color: '#008080', fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' ,marginLeft:'25px'}}>We Will Here For You!</h5>

                <p style={{ alignItems: 'auto' ,marginLeft:'25px'}}>
                  <IoIosPhonePortrait style={{ marginRight: '1px', fontSize: '5em', color: 'black',marginLeft:'10px' }} /> {/* Adjust size and color */}
                  <span>9430085302</span>
                </p>
                <p style={{ alignItems: 'auto' ,marginLeft:'25px'}}>
                  <MdMarkEmailRead style={{ marginRight: '1px', fontSize: '5em', color: 'black',marginLeft:'10px' }} /> {/* Adjust size and color */}
                  <span>support@upholdlegal.com</span>
                </p>

                <p style={{ alignItems: 'auto',marginLeft:'25px' }}>
                  <FaHome style={{ marginRight: '1px', fontSize: '5em', color: 'black',marginLeft:'10px' }} /> {/* Adjust size and color */}
                  <span style={{ display: 'block' }}>560063, Bengaluru</span>
                  <span style={{ display: 'block' }}>Karnataka, India</span>
                </p>
              </div>
            </div>

            <div style={{ width: '70%', padding: '20px' }}>
              <div style={{ ...sectionShadowStyle }}> {/* Apply shadow here */}
                <Appointment />
              </div>
            </div>
          </div>
        </ChakraProvider>
      )}

      <Footer />
    </div>
  );
};

export default Contact;
