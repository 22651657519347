import React from "react";
import NavbarTop from "./NavbarTop";

import { Text, Flex, Box, Image } from "@chakra-ui/react"; // Importing Chakra UI components


import { useMediaQuery } from "react-responsive"; // Importing useMediaQuery hook from react-responsive
import { Link } from "react-router-dom"; // Importing Link component from React Router for navigation

// Importing image (assuming it's a relative path)
const img = "./logo.jpeg";

function NavbarTopFirst() {
  // Checking if device is tablet or mobile
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 604px)" });

  console.log(isTabletOrMobile, 'isTabletOrMobile-----')
  return (
    <>
      {isTabletOrMobile === false ? (
        <Box fontSize={"14px"} backgroundColor="#FFFFFF" borderBottom="3px solid black" width="100%" top="8" left="0" right={'0px'} zIndex="1000" position='fixed'>
          <Flex
            justifyContent="space-between"
            t={10}
            maxWidth={"80%"}
            mx="auto"
            alignItems={"center"}
          >
            {/* Logo */}
            <Flex alignItems="center">
              <Link to="/">
                <Image
        
                  width={{ base: "200px", sm: "300px", md: "170px" }} // Adjust width for breakpoints
                  height="100" 
            
                  objectFit="cover"
                  src={img}
                  alt="UpholdLegal logo"
                  cursor="pointer"
                />
              </Link>

            </Flex>
            <Flex alignItems={"center"}></Flex>
            {/* Flex container for the right side content */}
            <Flex alignItems={"center"} justifyContent={"space-between"} w="30%">
              {/* Links */}
              <Text p={3}>
                <Link
                  to="/about"
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  About
                </Link>
              </Text>
              <Text p={3}>
                <Link
                  to="/Services offered"
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  Paralegal Services
                </Link>
              </Text>
              <Text p={3}>
                <Link
                  to="/contact"
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  Contact
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Box>

      ) : (
        <Box
          fontSize="12px"
          backgroundColor="#FFFFFF"
          width="100%"
          top="70"
          zIndex="100"
          position="fixed"
          borderBottom="2px solid black"
        >
          <Flex
            justifyContent="space-between"
            maxWidth="100%"
            px="5px"  // Adjust padding as needed
            alignItems="center"
          >
            {/* Logo */}
            <Flex alignItems="center">
              <Link to="/">
                <Image
                  // boxSize={{ base: "80px", sm: "100px", md: "100px" }}
                   width={{ base: "250px", sm: "200px", md: "300px" }} 
                   height="70" 
                  objectFit="cover"
                  src={img}
                  alt="upholdlegal logo"
                />
              </Link>
            </Flex>

            {/* Flex container for the right side content */}
            <Flex alignItems="center" justifyContent="space-between" width="60%">
              {/* Links */}
              <Text p={1.5}>
                <Link to="/about" style={{ color: "#000000", textDecoration: "none" }}>
                  About
                </Link>
              </Text>
              <Text p={3}>
                <Link
                  to="/Services offered"
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  Paralegal Services
                </Link>
              </Text>
              <Text p={1.5}>
                <Link to="/contact" style={{ color: "#000000", textDecoration: "none" }}>
                  Contact
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Box>

      )}
    </>


  );
}

export default NavbarTopFirst;