import React, { useEffect } from 'react';
import NavbarTopFirst from '../Navbar/NavbarTopFirst';
import Footer from '../Footer/Footer';
import { FaFileAlt } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

const PrivacyPolicy = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 704px)' });
  const companyName = process.env.REACT_APP_COMPANY_NAME;

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <NavbarTopFirst />
      <header style={{
        position: 'relative', width: '100%', backgroundColor: '#008080', color: 'white', padding: '20px', marginBottom: '1%', marginTop: '130px'
      }}>
        <h1 style={{ margin: 0, fontWeight: 'bold', fontSize: '2.5rem', textAlign: 'center' }}>Privacy Policy</h1>
      </header>

      <div style={{
        position: 'fixed',
        margin: 10,
        top: '130px',
        width: '100%',
        zIndex: 1000,
        backgroundColor: '#fff',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }} />

      <main style={{
        padding: isTabletOrMobile ? '40px' : '20px',
        backgroundColor: '#f9f9f9',
        margin: isTabletOrMobile ? '-20px' : '20px',
        maxWidth: '1500px',
        borderRadius: '10px',
        marginBottom: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: '"Arial", sans-serif',
      }}>
        <section style={{ marginBottom: '40px' }}>
          <h2 style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '2rem',
            color: '#008080',
            borderBottom: '3px solid #008080',
            paddingBottom: '10px',
            marginBottom: '20px'
          }}>
            <FaFileAlt size={40} style={{ marginRight: '15px', color: '#008080' }} />
            Privacy Policy
          </h2>

          <p style={{ fontSize: '1rem', lineHeight: '1.8', color: '#333333', textAlign: 'justify' }}>
            Effective Date: <strong>15th Sep. 2024</strong><br />
            Last Updated: <strong>31th Aug. 2024</strong><br />
          </p>
          <p style={{ fontSize: '1rem', lineHeight: '1.8', color: '#333333', textAlign: 'justify' }}>
            At <strong>{companyName}</strong> (referred to as "we", "us", or "our"), we are committed to protecting the privacy of users ("you", "your", or "User") who visit our website or provide personal information through other communication channels. This Privacy Policy outlines how we collect, use, and manage personal data you provide to us whether through our website, email, phone, or other means.
          </p>
        </section>

        {[
          { id: 1, title: 'Acceptance of Policy', content: `This Privacy Policy is an integral part of our Website Terms and Conditions ("T&C") and by providing personal information to ${companyName} you agree to its terms. If you do not accept the Privacy Policy please refrain from submitting any personal information.` },
          { id: 2, title: 'Data Collection', content: 'We prioritize your privacy and data security. Personal information may be collected in the following ways: Registered Users: To become a Registered User of our website you may be required to provide personal data including but not limited to your name, address, phone number, and email address. This information is necessary for registration and communication purposes.' },
          { id: 3, title: 'Use of Personal Data', content: 'The personal data you provide is used primarily for administrative purposes and to fulfill the requests for which it was submitted. Additionally, we may use your data to understand and meet your service needs, tailor content to align with your preferences, and enhance our customer service, marketing efforts, and service delivery.' },
          { id: 4, title: 'Data Sharing and Disclosure', content: 'Your personal data is treated with the highest level of confidentiality and we do not sell your data to third parties for commercial gain. However, we may share your data in the following instances: Authorized Representatives: We may disclose your information to consultants, advisors, service providers, or agents to help us achieve the purposes outlined in this Privacy Policy.' },
          { id: 5, title: 'Consent and Opt-Out Options', content: 'By submitting your data to us, you consent to the following: The use of your personal information for the purposes outlined in Section 3. The disclosure of your data to authorized representatives and business partners for communication and marketing purposes.' },
          { id: 6, title: 'Data Security and Retention', content: 'We follow stringent security measures to safeguard your personal information from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no data transmission over the internet can be guaranteed to be completely secure and you transmit information at your own risk.' },
          { id: 7, title: 'Access and Correction of Personal Information', content: 'You have the right to request access to the personal information we hold about you. You may also request corrections to any inaccuracies in your data. If you are unable to update your information through the website, you may contact us and we will assist you in updating your details.' },
          { id: 8, title: 'Cookies and Tracking Technologies', content: 'Our website may use cookies to enhance your browsing experience. Cookies are small text files stored on your device that allow us to recognize you when you return to our website. We may use cookies to personalize your user experience by tailoring content to your preferences and compile anonymous statistics on website usage to improve our services.' },
          { id: 9, title: 'Policy Changes', content: 'We reserve the right to amend this Privacy Policy as necessary to reflect changes in the law, best practices, or our business operations. The latest version of the policy will always be available on this page. It is your responsibility to review the policy periodically to ensure you are aware of any updates.' },
          { id: 10, title: 'Contact Information', content: 'If you have any questions or concerns about this Privacy Policy or how your personal information is handled please contact via email at <strong>support@upholdlegal.com</strong>' },
        ].map(section => (
          <section key={section.id} style={{ marginBottom: '40px', padding: '20px', backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05)' }}>
            <h3 style={{ color: '#008080', margin: '20px 0', fontSize: '1.5rem', fontWeight: '600' }}>{section.id}. {section.title}</h3>
            <p style={{ fontSize: '1rem', lineHeight: '1.8', color: '#333333' }} dangerouslySetInnerHTML={{ __html: section.content }} />
          </section>
        ))}
      </main>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
